import service from "@/http/request";


// 发送短信
export const send = (data) => {
  return service({
    url: "/api/send",
    method: "post",
    data
  });
};
// 上传手机号文件
export const upload = (data, progressCallback) => {
  return service({
    url: "/api/upload",
    method: "post",
    data,
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progressCallback(progress);
    },
  });
};
// 上传个性化文件
export const uploadBatch = (data, progressCallback) => {
  return service({
    url: "/api/uploadBatch",
    method: "post",
    data,
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progressCallback(progress);
    },
  });
};
// 上传变量模板短信文件
export const uploadTemplate = (data, progressCallback) => {
  return service({
    url: "/api/uploadTemplate",
    method: "post",
    data,
    onUploadProgress: (progressEvent) => {
      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progressCallback(progress);
    },
  });
};
// 下载示例文件
export const downloadDemoMobile = (data) => {
  return service({
    url: "/api/downloadDemoMobile",
    method: "post",
    data,
    responseType: "blob",
    fileName: "示例文件"
  });
};
// 下载个性化示例文件
export const downloadDemoMobileBatch = (data) => {
  return service({
    url: "/api/downloadDemoMobileBatch",
    method: "post",
    data,
    responseType: "blob",
    fileName: "个性化示例"
  });
};
// 下载变量模板短信示例文件
export const downloadDemoMobileTemplate = (data) => {
  return service({
    url: "/api/downloadDemoMobileTemplate",
    method: "post",
    data,
    responseType: "blob",
    fileName: "变量模板短信示例"
  });
};
//预览变量模板短信
export const echoTmplData = (data) => {
  return service({
    url: "/api/echoTmplData",
    method: "post",
    data
  });
};
// export const echoTmplData = (data, progressCallback) => {
//   return service({
//     url: "/api/echoTmplData",
//     method: "post",
//     data,
//     onUploadProgress: (progressEvent) => {
//       const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
//       progressCallback(progress);
//     },
//   });
// };
